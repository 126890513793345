import { ReactComponent as AskIco } from "../../../assets/icons/ask-ico.svg";
import { ReactComponent as ChatIco } from "../../../assets/icons/chat-ico.svg";
import { ReactComponent as SynastryChatIco } from "../../../assets/icons/synastry-chat-ico.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../redux/reduxTypes";
import ZodiacSymbol from "../ZodiacSymbol/ZodiacSymbol";
import { NavLink } from "react-router-dom";

const MobileMainFooter = () => {
  const userData = useAppSelector(
    (state) => state.chat.chatData.value?.account_info
  );
  const authData = useAppSelector((state) => state.authentication.authData);
  const navigate = useNavigate();

  const navLinks: Array<{
    text: string;
    end?: boolean;
    route: string;
    hide?: boolean;
    Icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    isLinkActive?: boolean;
  }> = [
    {
      text: "Ask",
      end: true,
      route: !authData ? "/chat-box-questions-public" : "/chat-box-questions",
      Icon: AskIco,
    },
    {
      text: "Chat",
      route: !authData ? "/chat-box-public" : "/chat-box",
      Icon: ChatIco,
    },
    {
      text: "Profile",
      route: "/profile",
      hide: !authData,
    },
    {
      text: "Synastry",
      route: "/synastry/landing",
      Icon: SynastryChatIco,
      hide: !authData,
      isLinkActive: window.location.pathname.includes("synastry"),
    },
  ];

  return (
    <div className="flex items-center justify-center gap-5 w-full py-1.5 mb-[7px] bg-transparent-gray bg-glass rounded-xl">
      {navLinks.map((navLink, index) => {
        return (
          !navLink.hide && (
            <NavLink
              key={index}
              to={navLink.route}
              end={navLink.end ? true : false}
            >
              {({ isActive }) => (
                <Button
                  type="border"
                  text={""}
                  CustomIco={
                    navLink.Icon ? (
                      <navLink.Icon
                        className={`w-[22px] h-[22px] ${
                          isActive || navLink.isLinkActive
                            ? "[&_path]:stroke-gold"
                            : ""
                        }`}
                      />
                    ) : (
                      <ZodiacSymbol
                        zodiac={`${userData?.report?.profile_stats?.sun}`}
                        className={`w-[20px] h-auto ${
                          isActive || navLink.isLinkActive
                            ? "[&_path]:fill-gold"
                            : ""
                        }`}
                      />
                    )
                  }
                  className="flex-row-reverse !p-[7px] bg-[#0D101A] border border-[#ffffff4e]"
                  customStyle={
                    isActive || navLink.isLinkActive
                      ? {
                          border: "border border-gold",
                          text: "text-gold font-medium",
                        }
                      : undefined
                  }
                />
              )}
            </NavLink>
          )
        );
      })}
    </div>
  );
};

export default MobileMainFooter;
