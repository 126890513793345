import { ReactNode } from "react";
import { ReactComponent as Aquarius } from "assets/icons/zodiac-symbols/aquarius.svg";
import { ReactComponent as Aries } from "assets/icons/zodiac-symbols/aries.svg";
import { ReactComponent as Cancer } from "assets/icons/zodiac-symbols/cancer.svg";
import { ReactComponent as Capricorn } from "assets/icons/zodiac-symbols/capricorn.svg";
import { ReactComponent as Gemini } from "assets/icons/zodiac-symbols/gemini.svg";
import { ReactComponent as Leo } from "assets/icons/zodiac-symbols/leo.svg";
import { ReactComponent as Libra } from "assets/icons/zodiac-symbols/libra.svg";
import { ReactComponent as Pisces } from "assets/icons/zodiac-symbols/pisces.svg";
import { ReactComponent as Sagittarius } from "assets/icons/zodiac-symbols/sagittarius.svg";
import { ReactComponent as Scorpio } from "assets/icons/zodiac-symbols/scorpio.svg";
import { ReactComponent as Taurus } from "assets/icons/zodiac-symbols/taurus.svg";
import { ReactComponent as Virgo } from "assets/icons/zodiac-symbols/virgo.svg";

const ZodiacSymbol = ({
  zodiac,
  className,
}: {
  zodiac: string;
  className?: string;
}) => {
  const getZodiacSymbol = (symbol: string): JSX.Element => {
    // Rendering components based on wizard step
    switch (symbol.toLowerCase()) {
      case "aquarius":
        return <Aquarius className={`${className}`} />;

      case "aries":
        return <Aries className={`${className}`} />;

      case "cancer":
        return <Cancer className={`${className}`} />;

      case "capricorn":
        return <Capricorn className={`${className}`} />;

      case "gemini":
        return <Gemini className={`${className}`} />;

      case "leo":
        return <Leo className={`${className}`} />;

      case "libra":
        return <Libra className={`${className}`} />;

      case "pisces":
        return <Pisces className={`${className}`} />;

      case "sagittarius":
        return <Sagittarius className={`${className}`} />;

      case "scorpio":
        return <Scorpio className={`${className}`} />;

      case "taurus":
        return <Taurus className={`${className}`} />;

      case "virgo":
        return <Virgo className={`${className}`} />;

      default:
        return <Virgo className={`${className}`} />;
    }
  };
  return getZodiacSymbol(zodiac);
};

export default ZodiacSymbol;
