import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const MainContentWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../../wrappers/MainContentWrapper")
    )
);

const PrivacyPolicy: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/PrivacyPolicy"))
);

const TermsOfUse: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/TermsOfUse"))
);

const legalRouter: routeType[] = [
  {
    path: "",
    element: <MainContentWrapper />,
    meta: {
      layout: "app",
      title: "Auth",
      type: "page",
      public: true,
    },
    children: [
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
        meta: {
          layout: "app",
          title: "Privacy policy",
          type: "page",
          public: true,
        },
      },
      {
        path: "terms-of-use",
        element: <TermsOfUse />,
        meta: {
          layout: "app",
          title: "Terms of use",
          type: "page",
          public: true,
        },
      },
    ],
  },

  //   {
  //     path: "terms-of-use",
  //     element: <NotExists />,
  //     meta: {
  //       layout: "app",
  //       title: "Not exist",
  //       type: "page",
  //       public: true,
  //     },
  //   },
];

export default legalRouter;
