import { type ReactNode } from "react";
import astroChart from "../../../assets/images/astro-chart.png";
import MainNavbar from "views/components/MainNavbar/MainNavbar";
import MainFooter from "views/components/MainFooter/MainFooter";
import { useAppSelector } from "../../../redux/reduxTypes";

interface appLayoutType {
  children: ReactNode;
}

const AppLayout = ({ children }: appLayoutType) => {
  const isMobile = useAppSelector((state) => state.global.isMobile);
  const authData = useAppSelector((state) => state.authentication.authData);

  return (
    <div className="w-full h-screen bg-wallpaper bg-no-repeat bg-cover object-cover overflow-y-hidden fade-in-animation">
      <MainNavbar />

      <>
        <div id="stars"></div>
        <div id="stars2"></div>
      </>

      {children}

      {!isMobile && (
        <div className="fixed rounded-full spin-animation -bottom-36 -right-36 opacity-100 w888:-bottom-32 w888:-right-44">
          <img
            src={astroChart}
            alt="astro-chart"
            className="w888:w-[400px] w888:h-[400px]"
          />
        </div>
      )}

      <MainFooter isMobile={isMobile} />
    </div>
  );
};

export default AppLayout;
