import { RefObject, type ReactNode } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

type propsTypes = {
  wheelPropagation?: boolean;
  onScrollY?: () => void;
  className?: string;
  children: ReactNode;
  style?: {};
  customRef?: RefObject<HTMLElement>;
};

const ScrollWrapper = ({
  wheelPropagation,
  onScrollY,
  className,
  children,
  style,
}: propsTypes) => {
  return (
    <PerfectScrollbar
      id={"customScrollWrapper"}
      component="div"
      className={`${className}`}
      options={{
        wheelPropagation: wheelPropagation ? wheelPropagation : false,
      }}
      style={{ ...style, position: "relative", overflow: "hidden" }}
      onScrollY={onScrollY}
    >
      {children}
    </PerfectScrollbar>
  );
};

export default ScrollWrapper;
