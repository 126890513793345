import { type FC } from "react";

export const isObjEmpty = (obj: any) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  } else {
    return false;
  }
};

export const isStringEmpty = (string: string) =>
  !!(
    typeof string === "object" ||
    !string ||
    string.length === 0 ||
    string.trim().length === 0
  );

export const formatDateAndTime = (
  value: string,
  formatting: {
    month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day: "numeric" | "2-digit" | undefined;
    year: "numeric" | "2-digit" | undefined;
  }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", {
    ...formatting,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date(value));
};

export const formatDate = (date: string) => {
  if (date) {
    const d = new Date(date);
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      d
    );
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      d
    );
    const day = new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(d);

    return `${month} ${day}. ${year}.`;
  } else {
    return null;
  }
};

export const formatPercentage = (value: number) => {
  return Math.round(value * 10000) / 100;
};

export const validateStringLength = (
  string: string,
  min: number,
  max: number
) =>
  !(
    typeof string === "object" ||
    !string ||
    string.length >= max ||
    string.length < min
  );

export const validatePassword = (string: string) => {
  const regex = /^(?=.*\d)(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return !(
    typeof string === "object" ||
    !string ||
    string.length === 0 ||
    string.trim().length === 0 ||
    !regex.test(string)
  );
};

export const validateEmail = (email: string): boolean => {
  if (
    String(email)
      .toLowerCase()
      .match(
        // new regex
        /^[a-zA-Z0-9.\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
  ) {
    return true;
  } else {
    return false;
  }
};

const hasRefreshed = () => {
  const refreshed: string | null = window.sessionStorage.getItem(
    "retry-lazy-refreshed"
  );
  return refreshed !== null ? JSON.parse(refreshed) : false;
};

// lazyRetry function prevents chunkLoad Errors if app is updated and client's page is not refreshed
// this works only with route-based code splitting
export const lazyRetry = function (
  componentImport: () => Promise<FC | any>
): Promise<FC | any> {
  return new Promise((resolve, reject) => {
    const refreshed: boolean = hasRefreshed();

    // try to import the component
    componentImport()
      .then((component: FC) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!refreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          window.location.reload();
          return; // refresh the page
        }

        reject(() => {
          window.location.href = "/";
        }); // there was an error
        // window.location.href = "/";
      });
  });
};

export const isStringJSON = (string: string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (err) {
    return false;
  }
};

export function getRandomId() {
  return Math.random().toString(36).substr(2, 5);
}

export function getRandomIntId() {
  return Math.floor(Math.random() * 90000) + 10000;
}

// takes a {} object and returns a FormData object
export var objectToFormData = function (obj: any, form?: any, namespace?: any) {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (Array.isArray(obj[property]) && !(obj[property] instanceof File)) {
        // if array
        for (var i = 0; i < obj[property].length; i++) {
          let arrFormKey = formKey + "[" + i + "]";
          var el = obj[property][i];

          if (el instanceof File || typeof obj[property][i] !== "object") {
            fd.append(arrFormKey, el);
          } else {
            objectToFormData(el, fd, arrFormKey);
          }
        }
      } else {
        if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          // if the property is an object, but not a File,
          // use recursivity.
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
};

export function millisToMinutesAndSeconds(millis: number) {
  const minutes: number = Math.floor(millis / 60000);
  const seconds: number = +((millis % 60000) / 1000).toFixed(0);

  const convertedTime: string =
    seconds == 60
      ? minutes + 1 + ":00"
      : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;

  return convertedTime;
}

function str_pad_left(number: number, pad: string, length: number) {
  return (new Array(length + 1).join(pad) + number).slice(-length);
}

export function secondsToMinutesAndSeconds(secondsValue: number) {
  secondsValue = Math.round(secondsValue);
  const minutes: number = Math.floor(secondsValue / 60);
  const seconds = secondsValue - minutes * 60;

  const finalTime =
    str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);

  return finalTime;
}

export const formateSecondsToHHMMSS = (value: number) => {
  const formatedSecondsToHHMMSS = new Date(value * 1000)
    .toISOString()
    .slice(11, 19);

  return formatedSecondsToHHMMSS;
};

export function getBrowser(agent: string) {
  console.log(agent);
  let agentFormatted = agent.toLocaleLowerCase();
  switch (true) {
    case agentFormatted.indexOf("edge") > -1:
      return { value: "ms-edge", human: "MS Edge" };
    case agentFormatted.indexOf("edg/") > -1:
      return { value: "edge-chromium", human: "Edge" };
    case agentFormatted.indexOf("opr") > -1 ||
      navigator.userAgent.indexOf("OPR"):
      return { value: "opera", human: "Opera" };
    case agentFormatted.indexOf("chrome") > -1:
      return { value: "chrome", human: "Chrome" };
    case agentFormatted.indexOf("trident") > -1:
      return { value: "ms-ie", human: "MS IE" };
    case agentFormatted.indexOf("firefox") > -1:
      return { value: "mozilla", human: "Mozilla Firefox" };
    // case agentFormatted.indexOf("mozilla") > -1:
    //   return { value: "mozilla", human: "Mozilla Firefox" };
    case agentFormatted.indexOf("safari") > -1:
      return { value: "safari", human: "Safari" };
    default:
      return { value: "other", human: "" };
  }
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase();

  return getBrowser(agent);
}

export const getTime = (value: Date) => {
  if (!value) return value;
  let date = value;

  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const isDateValid = (givenDate: Date, expiredDate: Date) => {
  if (givenDate.getTime() <= expiredDate.getTime()) {
    return true;
  }
  return false;
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatText = (answer: string) => {
  let formatedText =
    "<span>" + answer.split("\n").join("</span><span>") + "</span>";

  return formatedText;
};
