import MergeLayoutRoutes from "./MergeLayoutRoutes";

// ** Routes Imports
import userInfoRoutes from "views/features/getUserInfo/routes";
import chatRouter from "views/features/chatBox/routes";
import authRouter from "views/features/auth/routes";
import profileRouter from "views/features/profile/routes";
import stripeRouter from "views/features/stripe/routes";
import synastryRouter from "views/features/synastry/routes";
import legalRouter from "views/features/legal/routes";

// ** Merge Routes
const routes = [
  ...authRouter,
  ...userInfoRoutes,
  ...chatRouter,
  ...synastryRouter,
  ...profileRouter,
  ...stripeRouter,
  ...legalRouter,
];

const getRoutes = () => {
  const LayoutRoutes = MergeLayoutRoutes(routes);

  return LayoutRoutes;
};

export { routes, getRoutes };
