import { useNavigate } from "react-router";
import delphiLogo from "../../../assets/icons/delphi-logo-horizontal-text.png";
import Button from "../Button/Button";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxTypes";
import { signOut } from "firebase/auth";
import { setAuth } from "views/features/auth/reducer/authentication.reducer";
import { clearChatData } from "views/features/chatBox/reducer/chatBox.reducer";
import { auth } from "../../../firebaseConfig/firebaseConfig";
import NavbarLinks from "../NavbarLinks/NavbarLinks";
import { IoMdLogOut } from "react-icons/io";

const MainNavbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authData = useAppSelector((state) => state.authentication.authData);

  const handleSignOut = async () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("delphiAccessToken");
        dispatch(setAuth(null));
        dispatch(clearChatData());
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="relative z-50 flex justify-center w-full px-5 w888:px-2">
      <div className="flex items-center max-w-[1440px] w-full py-3 w888:py-2">
        <div className="flex mr-auto">
          <img
            src={delphiLogo}
            className="w-auto h-[35px] w888:h-[26px]"
            alt="delphi-logo-header"
          />
        </div>

        <NavbarLinks />

        {authData && (
          <Button
            type="goldMain"
            CustomIco={<IoMdLogOut className="w-[20px] h-auto w888:w-[17px]" />}
            text=""
            onClick={handleSignOut}
            className="!py-[4.5px] !px-[4.5px] ml-2 text-sm !gap-1.5"
            customStyle={{ icon: "w888:w-[15px] w888:h-auto" }}
          />
        )}

        {!authData ? (
          <Button
            type="goldMain"
            actionIco={true}
            text={sessionStorage.getItem("wizardPassed") ? "Sign Up" : "Log In"}
            onClick={() =>
              sessionStorage.getItem("wizardPassed")
                ? navigate("/auth/signup")
                : navigate("/auth/login")
            }
            className="!py-1 !px-3 text-sm !gap-1.5 w888:!py-[3px] w888:!px-2 w888:text-xs"
            customStyle={{ icon: "w888:w-[15px] w888:h-auto" }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MainNavbar;
