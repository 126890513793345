import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const MainContentWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../../wrappers/MainContentWrapper")
    )
);

const Profile: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Profile"))
);

const Report: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Report"))
);

const Settings: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Settings"))
);

const Support: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Support"))
);

const profileRouter: routeType[] = [
  {
    path: "/",
    element: <MainContentWrapper />,
    meta: {
      layout: "app",
      title: "Chat",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "profile",
        element: <Profile />,
        meta: {
          layout: "app",
          title: "Profile",
          type: "page",
          private: true,
        },
        children: [
          {
            path: "",
            element: <Report />,
            meta: {
              layout: "app",
              title: "Report",
              type: "page",
              private: true,
            },
          },
          {
            path: "settings",
            element: <Settings />,
            meta: {
              layout: "app",
              title: "Settings",
              type: "page",
              private: true,
            },
          },
          {
            path: "support",
            element: <Support />,
            meta: {
              layout: "app",
              title: "Support",
              type: "page",
              private: true,
            },
          },
        ],
      },
    ],
  },
];

export default profileRouter;
