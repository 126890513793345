import { useAppSelector } from "../../../redux/reduxTypes";
import Button from "../Button/Button";
import { ReactComponent as AskIco } from "../../../assets/icons/ask-ico.svg";
import { ReactComponent as ChatIco } from "../../../assets/icons/chat-ico.svg";
import { ReactComponent as SynastryChatIco } from "../../../assets/icons/synastry-chat-ico.svg";
import { ReactComponent as CreditsIco } from "../../../assets/icons/credits-ico.svg";
import { ReactComponent as AddIco } from "../../../assets/icons/add-ico.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ZodiacSymbol from "../ZodiacSymbol/ZodiacSymbol";
import CreatePortal from "../CreatePortal/CreatePortal";
import Modal from "../Modal/Modal";
import CreditsModal from "../CreditsModal/CreditsModal";

const NavbarLinks = () => {
  const authData = useAppSelector((state) => state.authentication.authData);
  const userData = useAppSelector(
    (state) => state.chat.chatData.value?.account_info
  );

  const [creditsModal, setCreditsModal] = useState(false);

  const navLinks: Array<{
    text: string;
    end?: boolean;
    route: string;
    hide?: boolean;
    Icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    isLinkActive?: boolean;
  }> = [
    {
      text: "Ask",
      end: true,
      route: !authData ? "/chat-box-questions-public" : "/chat-box-questions",
      hide: !authData && !window.location.pathname.includes("chat-box"),
      Icon: AskIco,
    },
    {
      text: "Chat",
      route: !authData ? "/chat-box-public" : "/chat-box",
      Icon: ChatIco,
      hide: !authData && !window.location.pathname.includes("chat-box"),
    },
    {
      text: "Profile",
      route: "/profile",
      hide: !authData,
    },
    {
      text: "Synastry",
      route: "/synastry/landing",
      Icon: SynastryChatIco,
      hide: !authData,
      isLinkActive: window.location.pathname.includes("synastry"),
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2.5 justify-center w888:hidden">
        {navLinks.map((navLink, index) => {
          return (
            !navLink.hide && (
              <NavLink
                key={index}
                to={navLink.route}
                end={navLink.end ? true : false}
              >
                {({ isActive }) => (
                  <Button
                    type="main"
                    text={navLink.text}
                    CustomIco={
                      navLink.Icon ? (
                        <navLink.Icon
                          className={`w-[18px] h-auto ${
                            isActive || navLink.isLinkActive
                              ? "[&_path]:stroke-gold"
                              : ""
                          }`}
                        />
                      ) : (
                        <ZodiacSymbol
                          zodiac={`${userData?.report?.profile_stats?.sun}`}
                          className={`w-[18px] h-auto ${
                            isActive || navLink.isLinkActive
                              ? "[&_path]:fill-gold"
                              : ""
                          }`}
                        />
                      )
                    }
                    className={`flex-row-reverse gap-2 !py-[5px] !px-3.5 text-sm font-light ${
                      isActive ? "" : ""
                    }`}
                    customStyle={
                      isActive || navLink.isLinkActive
                        ? {
                            border: "border border-gold",
                            text: "text-gold",
                          }
                        : undefined
                    }
                  />
                )}
              </NavLink>
            )
          );
        })}
        {!authData && !window.location.pathname.includes("chat-box") ? (
          <div className="bg-transparent-gray bg-glass w-[2px] h-[20px] rounded-full"></div>
        ) : (
          <></>
        )}
        {!authData && !window.location.pathname.includes("chat-box") ? (
          <></>
        ) : (
          <div
            className={`flex items-center gap-1 bg-transparent-gray bg-glass rounded-full py-[3px] px-1 ${
              !authData ? "mr-2.5" : ""
            }`}
            onClick={() => authData && setCreditsModal(true)}
          >
            <button
              className={`flex items-center justify-center gap-1 bg-[#E0EFFF1F] text-gold rounded-full px-3 py-[1px] font-light`}
              onClick={() => null}
            >
              <CreditsIco className="[&_path]:fill-gold w-[13px] h-auto" />
              {userData?.credits && (
                <span className="text-gold">{`${
                  userData?.credits ? userData?.credits : 0
                }`}</span>
              )}
            </button>
            {authData && (
              <button
                className={`flex items-center justify-center bg-[#E0EFFF1F] rounded-full p-[5px] font-light`}
                onClick={() => {}}
              >
                <AddIco className="w-[16px] h-auto [&_path]:fill-gold" />
              </button>
            )}
          </div>
        )}
      </div>

      {!authData && !window.location.pathname.includes("chat-box") ? (
        <></>
      ) : (
        <div
          className={`hidden items-center gap-1 bg-transparent-gray bg-glass rounded-full py-0.5 ${
            !authData ? "mr-1.5" : ""
          } px-1 w888:flex `}
          onClick={() => authData && setCreditsModal(true)}
        >
          <button
            className={`flex items-center justify-center gap-1 bg-[#E0EFFF1F] text-gold rounded-full pl-2 pr-2.5 py-[3px] font-light text-sm`}
            onClick={() => null}
          >
            <CreditsIco className="[&_path]:fill-gold w-[14px] h-auto" />
            {userData?.credits && (
              <span className="text-gold text-xs">{`${
                userData?.credits ? userData?.credits : 0
              }`}</span>
            )}
          </button>
          {authData && (
            <button
              className={`flex items-center justify-center bg-[#E0EFFF1F] rounded-full p-[5px] font-light`}
              onClick={() => {}}
            >
              <AddIco className="[&_path]:fill-gold w-[16px] h-auto" />
            </button>
          )}
        </div>
      )}

      {creditsModal && (
        <CreatePortal root="modal">
          <Modal close={() => setCreditsModal(false)}>
            <CreditsModal closeModal={() => setCreditsModal(false)} />
          </Modal>
        </CreatePortal>
      )}
    </>
  );
};

export default NavbarLinks;
