// ** Reducers Imports
import global from "./global";
import authentication from "views/features/auth/reducer/authentication.reducer";
import chat from "views/features/chatBox/reducer/chatBox.reducer";
import infoWizard from "views/features/getUserInfo/reducer/userData.reducer";
import stripe from "views/features/stripe/reducer/stripe.reducer";
import synastry from "views/features/synastry/reducer/synastry.reducer";
import profile from "views/features/profile/reducer/profile.reducer";

const rootReducer = {
  global,
  authentication,
  profile,
  chat,
  synastry,
  infoWizard,
  stripe,
};

export default rootReducer;
