import { ReactComponent as CopyIco } from "../../../assets/icons/clipboard-ico.svg";
import { ReactNode, useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const ClickClipboardCopy = (props: {
  placeholder?: string;
  text: string;
  disableTruncate?: boolean;
  copyIcoHover?: boolean;
  icon?: ReactNode;
  containterClassName?: string;
}) => {
  const [copied, setCopied] = useState(false);

  const copyClipboard = (value: string) => {
    if (copied) return;
    navigator.clipboard.writeText(value);

    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        copyClipboard(props.text);
      }}
    >
      <div
        className={`flex items-center bg-transparent-gray text-white bg-glass gap-2 py-1.5 px-5 rounded-full cursor-pointer group ${
          props.containterClassName
            ? props.containterClassName
            : props.containterClassName
        }`}
      >
        <button
          type="button"
          className={`w-full text-left ${
            props.disableTruncate
              ? ""
              : "whitespace-nowrap overflow-hidden text-ellipsis flex justify-start items-center gap-3"
          }`}
        >
          {props.icon ? props.icon : ""}
          <span className="">
            {props.placeholder ? props.placeholder : props.text}
          </span>
        </button>
        <CopyIco
          className={`inline select-none w-[25px] h-auto [&_path]:fill-white ${
            copied
              ? "hidden"
              : props.copyIcoHover
              ? "hidden group-hover:block"
              : ""
          }`}
        />
        <IoMdCheckmarkCircleOutline
          className={`w-[25px] h-auto ${copied ? "inline" : "hidden"}`}
        />
      </div>
    </div>
  );
};

export default ClickClipboardCopy;
