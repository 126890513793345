import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const MainContentWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../../wrappers/MainContentWrapper")
    )
);

const SynastryWrappar: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../wrappers/SynastryWrapper"))
);

const SynastryLanding: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/SynastryLanding"))
);

const SynastryWizard: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/SynastryWizard"))
);

const SynastryChat: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/SynastryChat"))
);

const synastryRouter: routeType[] = [
  {
    path: "/synastry",
    element: <MainContentWrapper />,
    meta: {
      layout: "app",
      title: "Synastry",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <SynastryWrappar />,
        meta: {
          layout: "app",
          title: "",
          type: "page",
          private: true,
        },
        children: [
          {
            path: "landing",
            element: <SynastryLanding />,
            meta: {
              layout: "app",
              title: "Synastry Landing",
              type: "page",
              private: true,
            },
          },
          {
            path: "wizard",
            element: <SynastryWizard />,
            meta: {
              layout: "app",
              title: "Synastry Wizard",
              type: "page",
              private: true,
            },
          },
          {
            path: "chat/:chatIndex",
            element: <SynastryChat />,
            meta: {
              layout: "app",
              title: "Synastry Chat",
              type: "page",
              private: true,
            },
          },
        ],
      },
    ],
  },
];

export default synastryRouter;
