import React, { ReactNode } from "react";
import { BsArrowRight } from "react-icons/bs";

type propsTypes = {
  type: "goldMain" | "main" | "border" | "naked";
  text?: string;
  actionIco?: boolean;
  className?: string;
  CustomIco?: ReactNode;
  disable?: boolean;
  customStyle?: {
    text?: string;
    border?: string;
    background?: string;
    icon?: string;
  };
  onClick?: () => void;
};

const Button = ({
  type,
  text,
  actionIco,
  className,
  CustomIco,
  disable,
  customStyle,
  onClick,
}: propsTypes) => {
  return type === "goldMain" ? (
    <button
      className={`flex items-center justify-center gap-2 bg-transparent-gray bg-glass text-gold border border-[#ffffff1d] rounded-full px-5 py-1.5 font-light w888:py-[5px] w888:px-4 w888:text-sm ${className} transition-all hover:opacity-80 disabled:opacity-65 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={disable}
    >
      {text && <span className="text-gold font-normal">{text}</span>}
      {actionIco && !CustomIco && (
        <BsArrowRight className={`text-xl ${customStyle?.icon}`} />
      )}
      {CustomIco && CustomIco}
    </button>
  ) : type === "main" ? (
    <button
      className={`flex items-center justify-center gap-2 ${
        customStyle?.border ? customStyle.border : ""
      } ${
        customStyle?.background
          ? customStyle.background
          : "bg-transparent-gray bg-glass"
      } ${
        customStyle?.text ? customStyle.text : "text-white"
      } rounded-full px-5 py-1.5 font-light w888:py-[5px] w888:px-4 w888:text-sm ${className} transition-all hover:opacity-80 disabled:opacity-65 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={disable}
    >
      {text && <span>{text}</span>}
      {actionIco && !CustomIco && (
        <BsArrowRight className={`text-xl ${customStyle?.icon}`} />
      )}
      {CustomIco && CustomIco}
    </button>
  ) : type === "border" ? (
    <button
      className={`flex items-center justify-center gap-2 ${
        customStyle?.border ? customStyle.border : "border border-white"
      } ${
        customStyle?.background ? customStyle.background : "bg-transparent"
      } ${
        customStyle?.text ? customStyle.text : "text-white"
      } rounded-full px-5 py-1 font-light w888:py-1 w888:px-4 w888:text-sm ${className} transition-all hover:opacity-80 disabled:opacity-65 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={disable}
    >
      {text && <span>{text}</span>}
      {actionIco && !CustomIco && (
        <BsArrowRight className={`text-xl ${customStyle?.icon}`} />
      )}
      {CustomIco && CustomIco}
    </button>
  ) : type === "naked" ? (
    <button
      className={`text-white rounded-full px-5 py-1.5 font-light w888:py-1 w888:px-4 w888:text-sm ${className} transition-all hover:opacity-80 disabled:opacity-65 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={disable}
    >
      {text && <span>{text}</span>}
      {actionIco && !CustomIco && (
        <BsArrowRight className={`text-xl ${customStyle?.icon}`} />
      )}
      {CustomIco && CustomIco}
    </button>
  ) : (
    <button className={`${className}`} disabled={disable}>
      {text}
    </button>
  );
};

export default Button;
